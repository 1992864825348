import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";

import MaintenanceScreen from './MaintenanceScreen'

import ReactGA from 'react-ga';

import { withOneTabEnforcer } from "react-one-tab-enforcer"
import { BrowserView, MobileView, isBrowser, isMobile, isIOS } from 'react-device-detect';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// import co_logo from "../images/clv_logo.png";
import co_logo from "./images/clv_logo.png";
import googlePlayStoreIcon from "./images/google-play-store-icon.png";
import appStoreIcon from "./images/app-store-icon.png";

const SuccessRegister = lazy(() => import('./jsx/pages/SuccessRegister'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
const DemoExam = lazy(() => import('./jsx/pages/DemoExam'));
const showPdfDetails = lazy(() => import('./jsx/pages/E-library/PdfViewElibrary'))
const ComingSoon = lazy(() => import('./jsx/pages/ComingSoon'))
const Error404 = lazy(() => import('./jsx/pages/Error404'))



const Login = lazy(() => {
    return new Promise(resolve => {
        // console.log("@1", process.env.REACT_APP_APIHOSTURL)
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});

/* const LoginTab = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/LoginTabScreen')), 500);
    });
});
 */
function App(props) {

    const dispatch = useDispatch();
    const [online, setOnline] = useState(true)
    const [maintenanceMode, setMaintenanceMode] = useState('');

    useEffect(() => {
        const path = window.location.pathname.split("/");

        if (process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/apiv2/") {
            if (path[1] !== 'page-reset-password' && path[1] !== 'page-register' && path[1] !== 'page-login-guest' && path[1] !== 'page-register-success') {
                // console.log("@1----",)
                checkAutoLogin(dispatch, props.history, path[1]);
            }
        } else {
            if (path[1] !== 'page-reset-password' && path[1] !== 'page-register' && path[1] !== 'page-login-guest' && path[1] !== 'page-register-success') {
                // console.log("@2----",)
                checkAutoLogin(dispatch, props.history, path[1]);
            }
        }

    }, [dispatch]);

    useEffect(() => {
        /* setOnline({
            online: typeof navigator.onLine === "boolean" ? navigator.onLine : true
        }); */
        setOnline(navigator.onLine);

        window.addEventListener("online", goOnline);
        window.addEventListener("offline", goOffline);

        /* Add Google Analytics */
        const TRACKINF_ID = "G-5H784JHKRL";
        ReactGA.initialize(TRACKINF_ID);

        return () => {
            window.removeEventListener("online", goOnline);
            window.removeEventListener("offline", goOffline);
        };
    }, []);

    useEffect(() => {
        /* const isMaintenanceData = localStorage.getItem('isMaintenance');
        console.log("isMaintenanceData===========", isMaintenanceData) */

        fetchMaintenanceMode()

    }, [])

    const fetchMaintenanceMode = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_APIHOSTURL + 'migration/checktime');
            const data = await response.json();
            setMaintenanceMode(data.value);
        } catch (error) {
            console.error('Error fetching maintenance mode:', error);
        }
    };


    const goOnline = () => {
        setOnline(true);
    }

    const goOffline = () => {
        setOnline(false);
    }

    const onClickGoFunction = () => {
        fetchMaintenanceMode()
    }
    const goToPlayStoreLink = (val) => {
        if (val === 1) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.schemaphic.crestest&pcampaignid=web_share';
        } else if (val === 2) {
            console.log("App store Link require")
        }

    }
    /* 
    {`${process.env.REACT_APP_APIHOSTURL == "https://lmsapi.clvdev.in/apiv2/" ? 
                    <Route exact path='/page-register' component={Login} />
                    :
                    <Route exact path='/page-register' component={SignUp} />
                    }`}
    */

    let routes = (
        <Switch>
            <Route exact path='/page-login' component={Login} />
            <Route exact path='/page-login-guest' component={Login} />
            <Route exact path='/page-register-success' component={SuccessRegister} />
            <Route exact path='/page-register' component={SignUp} />
            <Route exact path='/page-forgot-password' component={ForgotPassword} />
            <Route exact path='/page-reset-password/:userInfo' component={ResetPassword} />
            <Route exact path='/app-demoexam' component={DemoExam} />
            <Route exact path='/demo-e-library-pdf/:infoid' component={showPdfDetails} />
            <Route exact path='/page-coming-soon' component={ComingSoon} />
            {/* <Route component={Error404} /> */}
        </Switch>
    );

    if (!isMobile) {
        if (props.isAuthenticated) {
            return (
                <>
                    {!online ?
                        <div className="no_inter_container">
                            <div>
                                <i className="bi bi-wifi-off font_100"></i>
                            </div>
                            <h1 style={{ marginBottom: '5px' }}>Check your internet connection</h1>
                            <div>
                                <h4 style={{ margin: '0' }}> It appears to be disconnected</h4>
                            </div>
                        </div>
                        :
                        <Suspense fallback={
                            <div id="preloader" onClick={onClickGoFunction()}>
                                <div className="sk-three-bounce">
                                    <div className="sk-child sk-bounce1"></div>
                                    <div className="sk-child sk-bounce2"></div>
                                    <div className="sk-child sk-bounce3"></div>
                                </div>
                            </div>
                        }
                        >
                            {/* <Index /> */}
                            {maintenanceMode === 1 ? <MaintenanceScreen /> : <Index />}
                        </Suspense>
                    }
                </>
            );

        } else {
            return (
                <div className="vh-100">
                    <Suspense fallback={
                        <div id="preloader" onClick={onClickGoFunction()}>
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                    >
                        {/* {routes} */}
                        {maintenanceMode === 1 ? <MaintenanceScreen /> : routes}
                    </Suspense>
                </div>
            );
        }
    } else {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#ffcc00',
                paddingBottom: '50px',
                flexDirection:'column'
            }}>
                <div className="app-download-page-container">
                    <img
                        src={co_logo}
                        className="app-download-co-logo"
                        alt="Crestest App"
                    />
                </div>
                <div
                    className="show"
                >
                    <Modal.Dialog>
                        <Modal.Header >
                            <Modal.Title>Alert !</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center', color: '#1d5068' }}>For the best view and experience on your mobile, please download our Crestest Learning Mobile App.</p>

                            <p style={{ fontSize: '14px', fontWeight: '500', textAlign: 'center', color: '#962424', marginTop:'30px' }}>Download the App Now</p>
                        </Modal.Body>

                        <Modal.Footer>
                            {isIOS ?
                                <div className="download-app-button-container">
                                    <img
                                        src={appStoreIcon}
                                        className="download-app-button"
                                        alt="Crestest App"
                                        onClick={() => goToPlayStoreLink(2)}
                                    />
                                </div>
                                // <Button variant="secondary text-center" onClick={goToPlayStoreLink}>Download App IOS</Button>
                                :
                                <div className="download-app-button-container">
                                    <img
                                        src={googlePlayStoreIcon}
                                        className="download-app-button"
                                        alt="Crestest App"
                                        onClick={() => goToPlayStoreLink(1)}
                                    />
                                </div>
                                // <Button variant="secondary text-center" onClick={goToPlayStoreLink}>Download App AND</Button>
                            }

                        </Modal.Footer>
                    </Modal.Dialog>
                </div>
            </div>
        );

    }
    // }
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));